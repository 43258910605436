import { graphql } from "gatsby"
import { DEFAULT_LANG, HOME_PAGE_UID } from "../propreties"

export const linkResolver = (doc, parent) => {
  const lang = doc.lang && doc.lang !== DEFAULT_LANG ? `/${doc.lang}` : ""
  if (!doc.lang) {
    doc.lang = DEFAULT_LANG
  }
  switch (doc.type) {
    case "landing-page":
      return `${lang}/landing/${doc.uid}`
    case "blog-main-page":
      return `${lang}/blog`
    case "blog-category":
      return `${lang}/blog/${doc.uid}`
    case "blog-page":
      if (parent) {
        return `${lang}/blog/${parent._meta.uid}/${doc.uid}`
      }
      return `${lang}/blog/${doc.uid}`
    case "content-page":
      if (doc.uid === HOME_PAGE_UID) {
        return `${lang}/`
      }
      if (parent) {
        if (parent.parent) {
          return `${lang}/${parent.parent._meta.uid}/${parent._meta.uid}/${doc.uid}`
        }
        return `${lang}/${parent._meta.uid}/${doc.uid}`
      }
      return `${lang}/${doc.uid}`
    default:
      return "/404"
  }
}

export const linkFragment = graphql`
  fragment link on PRISMIC__Linkable {
    __typename
    _linkType
    ... on PRISMIC_ContentPage {
      _linkType
      _meta {
        uid
        type
        lang
      }
      parent {
        ... on PRISMIC_ContentPage {
          _meta {
            lang
            type
            uid
          }
          parent {
            ... on PRISMIC_ContentPage {
              _meta {
                type
                uid
                lang
              }
            }
          }
        }
      }
    }
    ... on PRISMIC_BlogMainPage {
      _linkType
      _meta {
        uid
        lang
        type
      }
    }
    ... on PRISMIC_BlogCategory {
      _linkType
      _meta {
        uid
        lang
        type
      }
    }
    ... on PRISMIC_BlogPage {
      _linkType
      _meta {
        uid
        lang
        type
      }
    }
    ... on PRISMIC__ExternalLink {
      _linkType
      url
    }
  }
`
